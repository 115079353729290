export default [
    [
        {
            id: 1,
            head: false,
            value: '',
            readOnly: false,
            className: [],
            layers: [
                {
                    layerType: 'text',
                    layersDescr: '',
                    hover: false,
                    id: Date.now(),
                },
            ],
        },
        // {
        //     id: 13,
        //     head: false,
        //     value: '',
        //     readOnly: false,
        //     className: [],
        //     layers: [
        //         {
        //             layerType: 'text',
        //             layersDescr: '',
        //             hover: false,
        //             id: Date.now(),
        //         },
        //     ],
        // },
        // {
        //     id: 12,
        //     head: false,
        //     value: '',
        //     readOnly: false,
        //     className: [],
        //     layers: [
        //         {
        //             layerType: 'text',
        //             layersDescr: '',
        //             hover: false,
        //             id: Date.now(),
        //         },
        //     ],
        // },
        // {
        //     id: 125,
        //     head: false,
        //     value: '',
        //     readOnly: false,
        //     className: [],
        //     layers: [
        //         {
        //             layerType: 'text',
        //             layersDescr: '',
        //             hover: false,
        //             id: Date.now(),
        //         },
        //     ],
        // },
        // {
        //     id: 126,
        //     head: false,
        //     value: '',
        //     readOnly: false,
        //     className: [],
        //     layers: [
        //         {
        //             layerType: 'text',
        //             layersDescr: '',
        //             hover: false,
        //             id: Date.now(),
        //         },
        //     ],
        // },
        // {
        //     id: 13818801,
        //     head: false,
        //     value: '',
        //     readOnly: false,
        //     className: [],
        //     layers: [
        //         {
        //             layerType: 'text',
        //             layersDescr: '',
        //             hover: false,
        //             id: Date.now(),
        //         },
        //     ],
        // },
        // {
        //     id: 13818804,
        //     head: false,
        //     value: '',
        //     readOnly: false,
        //     className: [],
        //     layers: [
        //         {
        //             layerType: 'text',
        //             layersDescr: '',
        //             hover: false,
        //             id: Date.now(),
        //         },
        //     ],
        // },
        // {
        //     id: 138184804,
        //     head: false,
        //     value: '',
        //     readOnly: false,
        //     className: [],
        //     layers: [
        //         {
        //             layerType: 'text',
        //             layersDescr: '',
        //             hover: false,
        //             id: Date.now(),
        //         },
        //     ],
        // },
    ],
    // [
    //     {
    //         id: 155,
    //         head: false,
    //         value: 'Акушерско-гинекологические	',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: 'Акушерско-гинекологические',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 1355,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 12255,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 122556,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 122557,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 13810551,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 13810444,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 1381448804,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    // ],
    // [
    //     {
    //         id: 188,
    //         head: false,
    //         value: 'Аллергологические',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: 'Аллергологические',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 1388,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 13288,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 1389,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 13810,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 13810199,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 13810499,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 13814488074,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    // ],
    // [
    //     {
    //         id: 188,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: 'Аптеки',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 1388,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 13288,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 1389,
    //         head: false,
    //         value: '	',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 13810,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 13810133,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 13810422,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 138448804,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    // ],
    // [
    //     {
    //         id: 188,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '- из них: изготавливающие лекарственные препараты',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 1388,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 13288,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 1389,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 13810,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     }, {
    //         id: 1381016,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 1381046,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 1366804,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    // ],
    // [
    //     {
    //         id: 188,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: 'Восстановительного лечения',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 1388,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 13288,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 1389,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 13810,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     }, {
    //         id: 1381014,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 1381044,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 13819904,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    // ],
    // [
    //     {
    //         id: 188,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: 'Аллергологические',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 1388,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 13288,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 1389,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 13810,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 138101,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 138104,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    //     {
    //         id: 138804,
    //         head: false,
    //         value: '',
    //         readOnly: false,
    //         className: [],
    //         layers: [
    //             {
    //                 layerType: 'text',
    //                 layersDescr: '',
    //                 hover: false,
    //                 id: Date.now(),
    //             },
    //         ],
    //     },
    // ],
]
